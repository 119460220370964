import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Pagination } from "antd";

import { ColorRing } from 'react-loader-spinner';
import { _fetch } from '../config/Api'
import { base_url } from '../config/config'
import dateFormat from "dateformat";
const Activity = () => {
    const [data, setData] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setpageSer] = useState(0);

    const handledashboard = async (page = 1, pageSize = 10) => {
        try {
            const skip = (page - 1) * pageSize;
            setpageSer(skip);
            const requestData = {
                limit: pageSize,
                skip: skip,
              


            };

            let response = await _fetch(`${base_url}api/trader/activitys`, 'post', requestData);

            if (response.success === true) {
                setData(response);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    }


    useEffect(() => {
        handledashboard(currentPage, 10);
    }, [currentPage]);
    const handlePaginationChange = (pageOne) => {
        setCurrentPage(pageOne);

    };

    // const records = () => {
    //     if (data && data?.data && data?.data?.length > 0) {
    //         return data?.data?.map((row, i) => {
    //             const serialNumber = pageSer + i + 1;
    //             return (
    //                 <tr className="table-row" key={i}>
    //                     <td className="tabel-text w inter">{serialNumber}</td>
    //                     <td className="tabel-text w inter">{row?.userId?.name}</td>
    //                     <td className="tabel-text w inter">{row?.userId?.userId}</td>
    //                     <td className="tabel-text w inter">{row?.activity}</td>
    //                     <td className="tabel-text w inter">{row?.discription}</td>
    //                     <td className="tabel-text w inter">{row?.message}</td>
                 
                        
                        
    //                     <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>
    //                     <td>{dateFormat(row.updatedAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>


    //                 </tr>
    //             );
    //         });
    //     } else {
    //         return (
    //             <tr>
    //                 <td colSpan="13">Data Not Found</td>
    //             </tr>
    //         );
    //     }
    // };
   
   
    const records = () => {
        if (data && data?.data && data?.data?.length > 0) {
            return data?.data?.map((row, i) => {
                const serialNumber = pageSer + i + 1;
                return (
                    <div key={i} className="container mt-5">
                        <div className="card border-primary">
                            <div className="card-header bg-white d-flex justify-content-between align-items-center">
                                <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <dl className="row mb-0">
                                            <dt className="col-sm-5">Activity:</dt>
                                            <dd className="col-sm-7">{row?.activity}</dd>
                                            <dt className="col-sm-5">Name:</dt>
                                            <dd className="col-sm-7">{row?.userId?.name}</dd>
                                            <dt className="col-sm-5">User Id:</dt>
                                            <dd className="col-sm-7">{row?.userId?.userId}</dd>
                                            <dt className="col-sm-5">Message:</dt>
                                            <dd className="col-sm-7">{row?.message}</dd>
                                            <dt className="col-sm-5">Date</dt>
                                            <dd className="col-sm-7">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
                                            {/* <dt className="col-sm-5"> Update Date</dt>
                                            <dd className="col-sm-7"> {dateFormat(row.updatedAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd> */}
                                           
                                        </dl>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            return <div>Data Not Found</div>;
        }
    };
     return (
        <>
            <div className="section2">

                <div className="table-sec">
                    <div className="transactions">

                    {records()}
                    </div>
                    <div className="pagination">
                        <Pagination
                            style={{ cursor: "pointer" }}
                            className="mx-auto"
                            current={currentPage || 1}
                            total={data?.count || 0}
                            pageSize={10}
                            onChange={handlePaginationChange}
                            showSizeChanger={false}
                            showQuickJumper={false}
                        />

                    </div>

                </div>
            </div>
        </>
    )
}

export default Activity
