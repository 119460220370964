import React, { useEffect, useState } from 'react';
import _fetch from '../config/Api';
import { base_url } from '../config/config';

const Admin = () => {
  const [userInfo, setUserInfo] = useState('');
  const [data, setData] = useState({});
  const [name, setName] = useState({});


  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    try {
      // let response = await _fetch(`${base_url}admin/users`, 'POST', {}, {});
      let response = await _fetch(`${base_url}api/trader/dashboard`, 'GET', {}, {});

      // console.log("response", response);
      if (response.success === true) {
        setUserInfo(response.data);
 
        setName(response?.data.trader);
        // console.log(response.data);

      } else {
        console.log("error", response.message);
      }
    } catch (error) {
      console.error('Error details:', error);
    }
  };

  console.log('userInfo',userInfo);
  
  return (
    <>
      {/* <div className='row'>
        <div className="col-auto icon-box">
          <div className="icon-box1 ">
            <div className="box">
              <h6>members</h6>
              <p>{userInfo?.members}</p>
            </div>
            {/* <div className="box">
              <h6>Trader</h6>
              <p>{userInfo?.traders}</p>
            </div>
            <div className="box">
              <h6>Total Investment</h6>
              <p>{userInfo?.total_investment}</p>
            </div>
            <div className="box">
              <h6>Total Joinngs</h6>
              <p>{userInfo?.today_joinngs}</p>
            </div>
            <div className="box">
              <h6>Total Investment</h6>
              <p>{userInfo?.today_investment}</p>
            </div>
            <div className="box">
              <h6>Paid Users</h6>
              <p>{userInfo?.paid_users}</p>
            </div>
            <div className="box">
              <h6>Total Investments</h6>
              <p>{userInfo?.total_investments}</p>
            </div>
            <div className="box">
              <h6>Verified Users</h6>
              <p>{userInfo?.verified_users}</p>
            </div>
            <div className="box">
              <h6>Total Withdraw Amount</h6>
              <p>{userInfo?.total_withdraw_amount}</p>
            </div>

            <div className="box">
              <h6>Withdraw Count</h6>
              <p>{userInfo?.withdraw_count}</p>
            </div>
            <div className="box">
              <h6>Total Payable Amount</h6>
              <p>{userInfo?.total_payable_amount}</p>
            </div>
            <div className="box">
              <h6>Today Roi Count</h6>
              <p>{userInfo?.today_roi_count}</p>
            </div>
            <div className="box">
              <h6>Total Deposited Usdt</h6>
              <p>{userInfo?.total_deposited_usdt}</p>
            </div>
            <div className="box">
              <h6>Admin Emergency Fund</h6>
              <p>{userInfo?.admin_emergency_fund}</p>
            </div>
            <div className="box">
              <h6>Converted Fund</h6>
              <p>{userInfo?.converted_fund}</p>
            </div>
            <div className="box">
              <h6>Today Roi Income</h6>
              <p>{userInfo?.today_roi_income}</p>
            </div>
            <div className="box">
              <h6>Today Roi Level Income</h6>
              <p>{userInfo?.today_roi_level_income}</p>
            </div> 
          </div>
        </div>
      </div> */}
{/* <div className="row p-5 py-md-5">
        <div className="rounded shadow">
          <div className="d-flex align-items-center justify-content-center">
            <div className="">
              <div className="p-3">
                <h5 className="mb-0">{name?.name || "N/A"}</h5>
              </div>
              <div className="p-3">
                <h6 className="mb-0">{name?.traderId || "N/A"}</h6>
              </div>
              <div className="p-3">
                <h5 className="mb-0">{name?.email || "N/A"}</h5>
              </div>
              <div className="p-3">
                <h6>
                  {/* Balance: {data?.walletBalance ? Number(data?.walletBalance).toFixed(4) : "0"} {data?.asset || ""} */}
                {/* </h6>
              </div>

            </div>
            <div className="vr mt-3"></div>
            <div className="cr">
              <div className="p-3">
              <h6>members</h6>
              <p>{userInfo?.members}</p>
              </div>
             
             
            </div>
          </div>
          <hr />
        </div>
      </div> */}

<div className="row p-3 p-md-5 uio">
        
        <div className="rounded shadow">
          <div className="d-flex align-items-center justify-content-center">
            <div className="itd ">
              <div className="p-3">
                <h5 className="mb-0">{name?.name || "N/A"}</h5>
              </div>
              <div className="p-3">
                <h6 className="mb-0">{name?.traderId || "N/A"}</h6>
              </div>
              <div className="p-3 ">
                <h5 className="mb-0 ">{name?.email || "N/A"}</h5>
              </div>
              <div className="p-3">
                <h6> 
                  Balance: {data?.walletBalance ? Number(data?.walletBalance).toFixed(4) : "0"} {data?.asset || ""}
                </h6>
              </div>

            </div>
            <div className="vr mt-3"></div>
            <div className="cr ">
              <div className="p-3">
                <h6>members</h6>
                <p>{userInfo?.members}</p>
              </div>
              
            </div>
          </div>
          <hr />
        </div>
      </div>

      <div className="d-flex justify-content-between p-3">
        {/* <img src={icon} alt="icon" /> */}
        {/* <button type='button' className='btn btn-success'>Deposit</button> */}
      </div>
    </>
  );
};

export default Admin;
