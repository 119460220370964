import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { _fetch } from '../config/Api'
import { base_url } from '../config/config'
import { ColorRing } from 'react-loader-spinner';
import dateFormat from "dateformat";
import { useNavigate } from 'react-router-dom';
import { Pagination } from "antd";
const Tradermember = () => {
    const [data, setData] = useState()
    const [spotBalances, setSpotBalances] = useState({});
    const [futureBalances, setFutureBalances] = useState({});
    const [loading, setLoading] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setpageSer] = useState(0);
    const navigate = useNavigate();
    const handledashboard = async (page = 1, pageSize = 10) => {
        try {

            const skip = (page - 1) * pageSize;
            setpageSer(skip);
            const requestData = {
                limit: pageSize,
                skip: skip
            };
            let response = await _fetch(`${base_url}api/trader/members`, 'post', requestData);

            if (response.success === true) {
                setData(response);
            } else {
                toast.dismiss()
                toast.error(response.message);

            }
        } catch (error) {
            console.log("Error details", error);
        }
    }

    const getbalance = async (_id, type) => {

        setLoading((prevLoading) => ({
            ...prevLoading,
            [_id]: { ...prevLoading[_id], [type]: true }
        }));

        try {
            const requestData = {
                userId: _id,
                type: type
            };

            let response = await _fetch(`${base_url}api/trader/walletBalance`, 'post', requestData);
            console.log('response', response);

            if (response.success === true) {
                if (type === 'SPOT') {
                    setSpotBalances((prevBalances) => ({ ...prevBalances, [_id]: response.data }));
                } else if (type === 'FUTURE ') {
                    setFutureBalances((prevBalances) => ({ ...prevBalances, [_id]: response.data }));
                }
                toast.dismiss()
                toast.success(`${type} balance fetched successfully`);
            } else {
                toast.dismiss()
                toast.error(response.message);
            }
        } catch (error) {
            console.error('Error details:', error);
        } finally {

            setLoading((prevLoading) => ({
                ...prevLoading,
                [_id]: { ...prevLoading[_id], [type]: false }
            }));
        }
    };

    useEffect(() => {
        handledashboard(currentPage, 10);
    }, [currentPage]);
    const handlePaginationChange = (pageOne) => {
        setCurrentPage(pageOne);

    };

    const handleStatusChange = (userId, selectedValue) => {
        let url = "";

        switch (selectedValue) {
            case "openview":
                url = `/View?id=${userId}`;
                break;

            default:
                return;
        }


        navigate(url);
    };



    const records = () => {
        if (data && data?.data && data?.data?.length > 0) {
            return data?.data?.map((row, i) => {
                const serialNumber = pageSer + i + 1;
                return (
                    <div key={i} className="container mt-5">
                        <div className="card border-primary">
                            <div className="card-header bg-white d-flex justify-content-between align-items-center">
                                <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <dl className="row mb-0">
                                            <dt className="col-sm-5">UserId:</dt>
                                            <dd className="col-sm-7">{row?.userId}</dd>
                                            <dt className="col-sm-5">Name:</dt>
                                            <dd className="col-sm-7">{row?.name}</dd>
                                            <dt className="col-sm-5">Email:</dt>
                                            <dd className="col-sm-7">{row?.email}</dd>
                                            <dt className="col-sm-5">Date</dt>
                                            <dd className="col-sm-7">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
                                        </dl>
                                    </div>
                                    <div className="col-md-6">
                                        <dl className="row mb-0">
                                            <dt className="col-sm-5">Spot Balance</dt>
                                            <dd className="col-sm-7">


                                                {loading[row._id]?.spot ? (
                                                    <ColorRing
                                                        height={35}
                                                        width={35}
                                                        colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']}
                                                        ariaLabel="loading"
                                                    />
                                                ) : (
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={() => getbalance(row._id, 'SPOT')}
                                                    >
                                                        {spotBalances[row._id]?.free
                                                            ? `${parseFloat(spotBalances[row._id].free).toFixed(4)} USDT`
                                                            : 'USDT'}
                                                    </button>
                                                )}
                                            </dd>
                                            <dt className="col-sm-5">Future Balance</dt>
                                            <dd className="col-sm-7 text-danger"> 
                                            {loading[row._id]?.future ? (
                                <ColorRing
                                    height={35}
                                    width={35}
                                    colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']}
                                    ariaLabel="loading"
                                />
                            ) : (
                                <button
                                    className='btn btn-primary'
                                    onClick={() => getbalance(row._id, 'FUTURE')}
                                >
                                    {futureBalances[row._id]?.walletBalance
                                        ? `${parseFloat(futureBalances[row._id].walletBalance).toFixed(4)} USDT`
                                        : 'USDT'}
                                </button>
                            )}
                                            </dd>
                                            <dt className="col-sm-5">view</dt>
                                            <dd className="col-sm-7">
                                                <button className="btn btn-primary" onClick={() => handleStatusChange(row._id, 'openview')}>View</button>
                                            </dd>


                                        </dl>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            return <div>Data Not Found</div>;
        }
    };
   
    return (
        <>
            <div className="section2">
                <div className="container">
                    <div className="table-sec">
                        <div className="transactions">
                        {records()}
                        </div>
                        <div className="pagination">
                            <Pagination
                                style={{ cursor: "pointer" }}
                                className="mx-auto"
                                current={currentPage || 1}
                                total={data?.count || 0}
                                pageSize={10}
                                onChange={handlePaginationChange}
                                showSizeChanger={false}
                                showQuickJumper={false}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tradermember;

