import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { _fetch } from '../config/Api'
import { base_url } from '../config/config'
import { ColorRing } from 'react-loader-spinner';
import dateFormat from "dateformat";
import { Pagination } from "antd";
const Positionhistory = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        type: "FUTURE",
      };


      let response = await _fetch(`${base_url}api/trader/orders`, 'post', requestData);

      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }



  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };

  // const records = () => {
  //   if (data && data?.data && data?.data?.length > 0) {
  //     return data?.data?.map((row, i) => {
  //       const serialNumber = pageSer + i + 1;
  //       return (
  //         <tr className="table-row" key={i}>
  //           <td className="tabel-text w inter">{serialNumber}</td>
  //           <td className="tabel-text w inter">{row?.userId?.userId}</td>
  //           <td className="tabel-text w inter">{row?.userId?.name}</td>

  //           <td className="tabel-text w inter">{row?.orderId}</td>
  //           <td className="tabel-text w inter">{row?.symbol}</td>
  //           <td className="tabel-text w inter">{row?.status}</td>
  //           <td className="tabel-text w inter">{row?.price}</td>
  //           <td className="tabel-text w inter">{row?.origQty}</td>
  //           <td className="tabel-text w inter">{row?.type}</td>
  //           <td className="tabel-text w inter">{row?.side}</td>
  //           <td className="tabel-text w inter">{row?.quantity}</td>
  //           <td className="tabel-text w inter">{row?.avgPrice}</td>
  //           <td className="tabel-text w inter">{row?.executedQty}</td>
  //           <td className="tabel-text w inter">{row?.cumQty}</td>
  //           <td className="tabel-text w inter">{row?.cumQuote}</td>
  //           <td className="tabel-text w inter">{row?.timeInForce}</td>
  //           <td className="tabel-text w inter">{row?.side}</td>
  //           <td className="tabel-text w inter">{row?.positionSide}</td>
  //           <td className="tabel-text w inter">{row?.stopPrice}</td>
  //           <td className="tabel-text w inter">{row?.workingType}</td>
  //           <td className="tabel-text w inter">{row?.origType}</td>
  //           <td className="tabel-text w inter">{row?.priceMatch}</td>
  //           <td className="tabel-text w inter">{row?.selfTradePreventionMode}</td>
  //           <td className="tabel-text w inter">{row?.goodTillDate}</td>
  //           <td className="tabel-text w inter">{row?.batchId}</td>
  //           <td className="tabel-text w inter">{row?.fee}</td>
  //           <td className="tabel-text w inter">{row?.exchangeName}</td>
  //           <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>


  //         </tr>
  //       );
  //     });
  //   } else {
  //     return (
  //       <tr>
  //         <td colSpan="13">Data Not Found</td>
  //       </tr>
  //     );
  //   }
  // };
  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <div key={i} className="container mt-5">
            <div className="card border-primary">
              <div className="card-header bg-white d-flex justify-content-between align-items-center">
                <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <dl className="row mb-0">
                      <dt className="col-sm-5">Name:</dt>
                      <dd className="col-sm-7">{row?.userId?.name}</dd>
                      <dt className="col-sm-5">User Id:</dt>
                      <dd className="col-sm-7">{row?.userId?.userId}</dd>
                      <dt className="col-sm-5">OrderId:</dt>
                      <dd className="col-sm-7">{row?.orderId}</dd>
                      <dt className="col-sm-5">Symbol:</dt>
                      <dd className="col-sm-7">{row?.symbol}</dd>
                      <dt className="col-sm-5">status:</dt>
                      <dd className="col-sm-7">{row?.status}</dd>
                      <dt className="col-sm-5">Cum Qty:</dt>
                      <dd className="col-sm-7">{row?.cumQty}</dd>
                      <dt className="col-sm-5">Cum Quote</dt>
                      <dd className="col-sm-7">{row?.cumQuote}</dd>
                      <dt className="col-sm-5">time In Force</dt>
                      <dd className="col-sm-7">{row?.timeInForce}</dd>

                    </dl>
                  </div>
                  <div className="col-md-4">
                    <dl className="row mb-0">
                      <dt className="col-sm-5">Orig Qty</dt>
                      <dd className="col-sm-7">{row?.origQty}</dd>
                      <dt className="col-sm-5">Type</dt>
                      <dd className="col-sm-7">{row?.type}</dd>
                      <dt className="col-sm-5">Side</dt>
                      <dd className="col-sm-7">{row?.side}</dd>
                      <dt className="col-sm-5">Quantity</dt>
                      <dd className="col-sm-7 text-danger">{row?.quantity}</dd>
                      <dt className="col-sm-5">Avg Price</dt>
                      <dd className="col-sm-7">{row?.avgPrice}</dd>
                      <dt className="col-sm-5">Executed Qty:</dt>
                      <dd className="col-sm-7 text-secondary">{row?.executedQty}</dd>
                      <dt className="col-sm-5">Position Side:</dt>
                      <dd className="col-sm-7">{row?.positionSide}</dd>
                      <dt className="col-sm-5">Stop Price</dt>
                      <dd className="col-sm-7">{row?.stopPrice}</dd>
                      <dt className="col-sm-5">Working Type</dt>
                      <dd className="col-sm-7">{row?.workingType}</dd>

                    </dl>
                  </div>
                  <div className="col-md-4">
                    <dl className="row mb-0">
                      <dt className="col-sm-5">Orig Type</dt>
                      <dd className="col-sm-7">{row?.origType}</dd>
                      <dt className="col-sm-5">Good Till Date</dt>
                      <dd className="col-sm-7">{row?.goodTillDate}</dd>
                      <dt className="col-sm-5">Batch Id</dt>
                      <dd className="col-sm-7">{row?.batchId}</dd>
                      <dt className="col-sm-5">Price Match</dt>
                      <dd className="col-sm-7">{row?.priceMatch}</dd>
                      <dt className="col-sm-5">SelfTradeMode</dt>
                      <dd className="col-sm-7 ">{row?.selfTradePreventionMode}</dd>
                      {/* <dt className="col-sm-5">profit :</dt>
                      <dd className={`  col-sm-7  ${row?.profitLoss < 0 ? 'text-red' : 'text-green'}`}>{row?.profitLoss}</dd> */}
                      <dt className="col-sm-5">Fee</dt>
                      <dd className="col-sm-7 ">{row?.fee}</dd>
                      <dt className="col-sm-5">Exchange Name</dt>
                      <dd className="col-sm-7 ">{row?.exchangeName}</dd>
                      <dt className="col-sm-5">Date:</dt>
                      <dd className="col-sm-7">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
                      <dt className="col-sm-5">CloseStatus:</dt>
                      <dd className="col-sm-7">
                        {row?.closeStatus === true ? (

                          <>
                            <p style={{ color: "red" }}>Closed</p>
                          </>
                        ) : (
                          <>
                            <p style={{ color: "green" }}>open</p>
                          </>

                        )}
                      </dd>
                    </dl>
                  </div>
                  {/* <div className="d-grid gap-2 d-md-block mt-2">
                    <button className="btn btn-danger" type="button">Close</button>
                    <button className="btn btn-primary" type="button">View</button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div>Data Not Found</div>;
    }
  };

  return (
    <>
      <div className="section2  ">

        <div className="table-sec">
          <div className="transactions">

            {records()}
          </div>
          <div className="pagination">
            <Pagination
              style={{ cursor: "pointer" }}
              className="mx-auto"
              current={currentPage || 1}
              total={data?.count || 0}
              pageSize={10}
              onChange={handlePaginationChange}
              showSizeChanger={false}
              showQuickJumper={false}
            />

          </div>

        </div>
      </div>
    </>
  )
}

export default Positionhistory
