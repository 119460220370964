import React, { useState } from 'react'

import { NavLink, useNavigate ,useLocation} from 'react-router-dom'
import {_fetch} from '../config/Api'
import { base_url } from '../config/config'
import toast from 'react-hot-toast'
import { ColorRing } from 'react-loader-spinner';

const  Forget = () => {
    const { state } = useLocation();
    const [email, setEmail] = useState(state?.email || "");
    const [password, setPassword] = useState();
    const [otp, setOtp] = useState();
    const [passwordconfirm, setPasswordConfirm] = useState();
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();


    const handleSignUp = async (e) => {
        e.preventDefault();
        if (!email || !password || !otp || !passwordconfirm) {
            toast.dismiss()
            toast.error("Please enter the details");
            return;
        }
        setLoading(true); 

        try {
            const ForgetData = {
                email: email,
                password: password,
                otp: otp,
                confirmpassword: passwordconfirm,
            };
            let response = await _fetch(`${base_url}api/trader/forgotePassword`, 'POST', ForgetData, {})

            // console.log("response", response);
            if (response.success === true) {
                toast.dismiss()
                toast.success(response.message);
                navigate('/');
            } else {
                toast.error(response.message);
            }
        }
        catch (error) {
            console.log("Error details", error);

        }finally {
            setLoading(false); 
        }
    }

    return (
        <>
            <div className="conatiner-fluid">
                <div className="d-flex justify-content-center align-items-center h-100">
                <div className="sign-page ios ">
                <div className="heading text-center ">
                            <h3>Forgot Password</h3>
                        </div>
                        <form >
                        <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email </label>
                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp"  readOnly/>
                            </div>
                           
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Otp </label>
                                <input type="text" id="name" onChange={(e) => setOtp(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                <input type="password" id="password" onChange={(e) => setPassword(e.target.value)} autoComplete='off' className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Confirm Password</label>
                                <input type="password" id="sponser" onChange={(e) => setPasswordConfirm(e.target.value)} autoComplete='off' className="form-control" />
                            </div>
                            <button type="submit" className="btn btn-primary" onClick={handleSignUp}>
                            {loading ? (
                                    <ColorRing
                                        height={20}
                                        width={20}
                                        color="#fff"
                                        ariaLabel="loading"
                                    />
                                ) : (
                                    'Submit'
                                )}
                                </button>
                            <div>
                                <div className="small fw-bold mt-2 pt-1 mb-0">
                                    Have an account? <NavLink to='/' className="link-danger">Login</NavLink>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forget