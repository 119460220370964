import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { _fetch } from '../config/Api'
import { base_url } from '../config/config'
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import dateFormat from "dateformat";
import { Pagination } from "antd";

const Tradeopen = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const navigate = useNavigate();
  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        type:"FUTURE",

      };

      let response = await _fetch(`${base_url}api/trader/trades`, 'post', requestData);

      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }



  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);

  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
   
  };

  const handleStatusChange = (userId, selectedValue) => {
    let url = "";

    switch (selectedValue) {
      case "openview":
        url = `/openview?id=${userId}`;
        break;

      default:
        return;
    }


    navigate(url);
  };

  // const records = () => {
  //   if (data && data?.data && data?.data?.length > 0) {
  //     return data?.data?.map((row, i) => {
  //       const serialNumber = pageSer + i + 1;
  //       return (
  //         <tr className="table-row" key={i}>
  //           <td className="tabel-text w inter">{serialNumber}</td>
  //           <td className="tabel-text w inter">{row?.userId?.userId}</td>
  //           <td className="tabel-text w inter">{row?.userId?.name}</td>
  //           <td className="tabel-text w inter">{row?.orderId}</td>
  //           <td className="tabel-text w inter">{row?.symbol}</td>
  //           <td className="tabel-text w inter">{row?.status}</td>
  //           <td className="tabel-text w inter">{row?.price}</td>
  //           <td className="tabel-text w inter">{row?.quantity}</td>
  //           <td className="tabel-text w inter">{row?.type}</td>
  //           <td className="tabel-text w inter">{row?.side}</td>
  //           <td className="tabel-text w inter">{row?.endPrice}</td>
  //           <td className="tabel-text w inter">{row?.startPrice}</td>
  //           <td className="tabel-text w inter">{row?.batchId}</td>
  //           <td className="tabel-text w inter">{row?.leverage}</td>
  //           <td className="tabel-text w inter">{row?.exchangeName}</td>

  //           <td
  //             className={`   ${row?.profitLoss < 0 ? 'text-red' : 'text-green'}`}
  //           >
  //             {row?.profitLoss?.toFixed(4)}
  //           </td>


  //           <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>
  //           {/* <td className="tabel-text w inter">{row?.closeStatus}</td> */}
  //           <td>
  //             {row?.closeStatus === true ? (

  //               <>
  //                 <p style={{ color: "red" }}>Closed</p>
  //               </>
  //             ) : (
  //               <>
  //                 <p style={{ color: "green" }}>open</p>
  //               </>

  //             )}


  //           </td>
  //           <td className="tabel-text w inter">
             
  //             <button className="btn btn-primary" onClick={() => handleStatusChange(row._id, 'openview')}>View</button>
             

  //           </td>


  //         </tr>
  //       );
  //     });
  //   } else {
  //     return (
  //       <tr>
  //         <td colSpan="13">Data Not Found</td>
  //       </tr>
  //     );
  //   }
  // };
  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <div key={i} className="container mt-5">
            <div className="card border-primary">
              <div className="card-header bg-white d-flex justify-content-between align-items-center">
                <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <dl className="row mb-0">
                      <dt className="col-sm-5">Name:</dt>
                      <dd className="col-sm-7">{row?.userId?.name}</dd>
                      <dt className="col-sm-5">User Id:</dt>
                      <dd className="col-sm-7">{row?.userId?.userId}</dd>
                      <dt className="col-sm-5">OrderId:</dt>
                      <dd className="col-sm-7">{row?.orderId}</dd>
                      <dt className="col-sm-5">Symbol:</dt>
                      <dd className="col-sm-7">{row?.symbol}</dd>
                      <dt className="col-sm-5">status:</dt>
                      <dd className="col-sm-7">{row?.status}</dd>
                      <dt className="col-sm-5">price</dt>
                      <dd className="col-sm-7">{row?.price}</dd>
                     

                    </dl>
                  </div>
                  <div className="col-md-4">
                    <dl className="row mb-0">
                      <dt className="col-sm-5">Quantity</dt>
                      <dd className="col-sm-7">{row?.quantity}</dd>
                      <dt className="col-sm-5">Type</dt>
                      <dd className="col-sm-7">{row?.type}</dd>
                      <dt className="col-sm-5">Side</dt>
                      <dd className="col-sm-7">{row?.side}</dd>
                      <dt className="col-sm-5">End Price</dt>
                      <dd className="col-sm-7 text-danger">{row?.endPrice}</dd>
                      <dt className="col-sm-5">Start Price</dt>
                      <dd className="col-sm-7">{row?.startPrice}</dd>
                      <dt className="col-sm-5">Batch Id</dt>
                      <dd className="col-sm-7 text-secondary">{row?.batchId}</dd>
                    
                      

                    </dl>
                  </div>
                  <div className="col-md-4">
                    <dl className="row mb-0">
                      <dt className="col-sm-5">Exchange Name</dt>
                      <dd className="col-sm-7">{row?.exchangeName}</dd>
                     
                      <dt className="col-sm-5">Batch Id</dt>
                      <dd className="col-sm-7">{row?.batchId}</dd>
                     
                      <dt className="col-sm-5">profit :</dt>
                      <dd className={`  col-sm-7  ${row?.profitLoss < 0 ? 'text-red' : 'text-green'}`}>{row?.profitLoss}</dd>
                      <dt className="col-sm-5">leverage</dt>
                      <dd className="col-sm-7">{row?.leverage}</dd>
                 
                      <dt className="col-sm-5">Date:</dt>
                      <dd className="col-sm-7">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
                      <dt className="col-sm-5">CloseStatus:</dt>
                      <dd className="col-sm-7">
                        {row?.closeStatus === true ? (

                          <>
                            <p style={{ color: "red" }}>Closed</p>
                          </>
                        ) : (
                          <>
                            <p style={{ color: "green" }}>open</p>
                          </>

                        )}
                      </dd>
                      

                    </dl>
                  </div>
                  <div className="d-grid gap-2 d-md-block mt-2">
                    <button className="btn btn-danger" type="button">Close</button>
                    <button className="btn btn-primary" onClick={() => handleStatusChange(row._id, 'openview')}>View</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div>Data Not Found</div>;
    }
  };
  return (
    <>

      <div className="section2">

        <div className="table-sec">
          <div className="transactions">
        

            {records()}
          
            <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Tradeopen


// const handledashboard = async (page = 1, pageSize = 10) => {
//   try {
//     const url = `${base_url}api/trader/trades?page=${page}&pageSize=${pageSize}&type=FUTURE`;
//     let response = await _fetch(url, 'post');

//     if (response.success === true) {
//       setData(response);
//     } else {
//       toast.error(response.message);
//     }
//   } catch (error) {
//     console.log("Error details", error);
//   }
// };