import React from 'react'
import './Assets/Css/Style.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Login from './Auth/Login'
import Admin from './AdminPanel/Admin';
import Private from "./Routing/Private"
import PrivateLayout from './Layout/PrivateLayout';
import Positionhistory from './AdminPanel/PositionHistory'
import Public from './Routing/Public';
import Tradeopen from './AdminPanel/Tradeopen'
import NewTrade from './AdminPanel/NewTrade'
import Tradermember from './Member/Tradermember'
import Verfication from './Auth/Verfication';
import Forget from './Auth/Forget';
import Verfication_forget from './Auth/verficationforgetotp';
import Closeposition from './AdminPanel/Closeposition'
import Openview  from './AdminPanel/Openview'
import Tradespotopen from './AdminPanel/Tradespotopen'
import Spottrade from './AdminPanel/Spottrade'
import Spotpositiontrade from './AdminPanel/Spotpositiontrade'
import Openspotview from './AdminPanel/Openspotview'
import Activity from './activity/Activity'
import View from './Member/View';
const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<Private />}>
            <Route path='/dashboard' element={<PrivateLayout><Admin /></PrivateLayout>} />
            <Route path='/tradermember' element={<PrivateLayout><Tradermember /></PrivateLayout>} />
            <Route path='/new_trade' element={<PrivateLayout><NewTrade /></PrivateLayout>} />
            <Route path='/positionhistory' element={<PrivateLayout><Positionhistory /></PrivateLayout>} />
            <Route path='/tradeopen' element={<PrivateLayout><Tradeopen /></PrivateLayout>} />
            <Route path='/closeposition' element={<PrivateLayout><Closeposition /></PrivateLayout>} />
            <Route path='/openview' element={<PrivateLayout><Openview /></PrivateLayout>} />
            <Route path='/order' element={<PrivateLayout><Tradespotopen /></PrivateLayout>} />
            <Route path='/spottrade' element={<PrivateLayout><Spottrade /></PrivateLayout>} />
            <Route path='/trader' element={<PrivateLayout><Spotpositiontrade /></PrivateLayout>} />
              <Route path='/openspotview' element={<PrivateLayout><Openspotview /></PrivateLayout>} />
              <Route path='/activity' element={<PrivateLayout><Activity /></PrivateLayout>} />
              <Route path='/view' element={<PrivateLayout><View /></PrivateLayout>} />
          </Route>

          <Route element={<Public />}>
            <Route path='/' element={<Login />} />
            <Route path='/Verfication' element={<Verfication />} />
            <Route path='/Verfication_forget' element={<Verfication_forget />} />
            <Route path='/Forget' element={<Forget />} />
          </Route>
        </Routes>
        <Toaster />
      </Router>
    </>
  )
}


export default App